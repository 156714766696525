import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import MRList from '../screens/MRManagement/List'
import FeederManagement from '../screens/Feeders'
import NoResource from '../screens/FileUpload/Files/NoResource'
import EditMR from '../screens/MRManagement/Edit'
import TCDetail from '../screens/EnergyAudit/TCDetails/TCDetail'
import Billing from '../screens/Billing/List'
import Help from '../screens/Help'
import ProfileView from '../screens/Profile/ProfileView'
import ProfileEdit from '../screens/Profile/ProfileEdit'
import FileUploadList from '../screens/FileUpload/FileUploadList'
import AuditResultContainer from '../screens/AuditResult/AuditResultContainer'
import TCAuditDetail from '../screens/EnergyAudit/TCAudit/TCAuditDetail'
import Dashboard from '../screens/Dashboard/Index'

const CioNavigator = () => {
  return (
    <Switch>
      <Route exact path='/'>
        <Redirect to='/dashboard' />
      </Route>
      <Route
        exact
        path='/dashboard'
        component={Dashboard}
      />
      <Route
        exact path='/mr-management'
        component={MRList}
      />
      <Route
        exact
        path='/mr-management/:id/edit'
        component={EditMR}
      />
      <Route exact path='/feeders' component={FeederManagement} />
      <Route
        exact
        path='/file-upload'
        component={FileUploadList}
      />
      <Route
        exact
        path='/tc-details'
        component={TCDetail}
      />
      <Route
        exact
        path='/tc-audit'
        component={AuditResultContainer}
      />
      <Route
        exact
        path='/billing'
        component={Billing}
      />
      <Route
        exact
        path='/profile'
        component={ProfileView}
      />
      <Route
        exact
        path='/help'
        component={Help}
      />
      <Route
        exact
        path='/profile/edit'
        component={ProfileEdit}
      />
      <Route
        exact
        path='/tc-audit/:id'
        component={TCAuditDetail}
      />
      <Route component={NoResource} />
    </Switch>
  )
}

export default CioNavigator
