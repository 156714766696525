import { API } from 'aws-amplify'
import { fetchUserAttributes, fetchUserDesignation } from '../utils/meta'
import { getHierarchyDataByProperty } from '../utils/hierarchy'

export const getTCs = (page, perPage, month, number, readingDay, mrCode, selectedHierarchy) => {
  const loggedInUserDesignation = fetchUserDesignation()

  // When AE makes an API call
  if (loggedInUserDesignation === 'ae') {
    return API.get('baseURL', '/ae/transformer-centres', {
      queryStringParameters: {
        page,
        perPage,
        month,
        ...(number && { number }),
        ...(readingDay.length >= 1 && { readingDay }),
        ...(mrCode.length >= 1 && { mrCode })
      }
    })
  }

  // When CIO / DT makes an API call
  if (loggedInUserDesignation === 'dt' || loggedInUserDesignation === 'cio') {
    // If no user in hierarchy is selected
    if (selectedHierarchy === 'All') {
      return API.get('baseURL', `/${loggedInUserDesignation}/transformer-centres`, {
        queryStringParameters: {
          page,
          perPage,
          month,
          ...(number && { number }),
          ...(readingDay.length >= 1 && { readingDay }),
          ...(mrCode.length >= 1 && { mrCode })
        }
      })
    }

    // When a user in hierarchy is selected by CIO/DT
    const {
      location: { areaCode, name: locationName }
    } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())

    return API.get('baseURL', `/${loggedInUserDesignation}/transformer-centres`, {
      queryStringParameters: {
        page,
        perPage,
        month,
        ...(number && { number }),
        ...(readingDay.length >= 1 && { readingDay }),
        ...(mrCode.length >= 1 && { mrCode }),
        location: locationName,
        locationCode: selectedHierarchy.location[areaCode]
      }
    })
  }

  // When CE-AEE makes an API call

  // When no user is selected in hierarchy by CE-AEE
  if (selectedHierarchy === 'All') {
    const {
      areaCode: cognitoAreaCode,
      location: { name: locationName }
    } = getHierarchyDataByProperty('designation', loggedInUserDesignation)
    const { [`custom:${cognitoAreaCode}`]: locationCode } = fetchUserAttributes()

    return API.get('baseURL', `/${loggedInUserDesignation}/transformer-centres`, {
      queryStringParameters: {
        page,
        perPage,
        month,
        ...(number && { number }),
        ...(readingDay.length >= 1 && { readingDay }),
        ...(mrCode.length >= 1 && { mrCode }),
        location: locationName,
        locationCode
      }
    })
  }

  // When a user in hierarchy is selected by CE-AEE
  const {
    location: { areaCode, name: locationName }
  } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())

  return API.get('baseURL', `/${loggedInUserDesignation}/transformer-centres`, {
    queryStringParameters: {
      page,
      perPage,
      month,
      ...(number && { number }),
      ...(readingDay.length >= 1 && { readingDay }),
      ...(mrCode.length >= 1 && { mrCode }),
      location: locationName,
      locationCode: selectedHierarchy.location[areaCode]
    }
  })
}

export const addTCReading = (transformerId, body) => {
  return API.post('baseURL', `/ae/transformer-centres/${transformerId}/readings`, {
    body
  })
}

export const editTCReading = (transformerId, body) => {
  return API.patch('baseURL', `/ae/transformer-centres/${transformerId}/readings`, {
    body
  })
}

export const getInstallations = (
  transformerId,
  month,
  page,
  perPage,
  rrNumber,
  selectedHierarchy
) => {
  const loggedInUserDesignation = fetchUserDesignation()
  if (loggedInUserDesignation === 'ae') {
    return API.get('baseURL', '/ae/installations', {
      queryStringParameters: {
        transformerId,
        month,
        page,
        perPage,
        ...(rrNumber && { RRNumber: rrNumber }),
        count: true
      }
    })
  }

  // When CIO / DT makes an API call
  if (loggedInUserDesignation === 'dt' || loggedInUserDesignation === 'cio') {
    // If no user in hierarchy is selected by DT/CIO
    if (selectedHierarchy === 'All') {
      return API.get('baseURL', `/${loggedInUserDesignation}/installations`, {
        queryStringParameters: {
          transformerId,
          month,
          page,
          perPage,
          ...(rrNumber && { RRNumber: rrNumber }),
          count: true
        }
      })
    }

    // When a user in hierarchy is selected by DT/CIO
    const {
      location: { areaCode, name: locationName }
    } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())

    return API.get('baseURL', `/${loggedInUserDesignation}/installations`, {
      queryStringParameters: {
        transformerId,
        month,
        page,
        perPage,
        ...(rrNumber && { RRNumber: rrNumber }),
        count: true,
        location: locationName,
        locationCode: selectedHierarchy.location[areaCode]
      }
    })
  }

  // When CE-AEE makes an API call

  // When no user is selected in hierarchy by CE-AEE
  if (selectedHierarchy === 'All') {
    const {
      areaCode: cognitoAreaCode,
      location: { name: locationName }
    } = getHierarchyDataByProperty('designation', loggedInUserDesignation)
    const { [`custom:${cognitoAreaCode}`]: locationCode } = fetchUserAttributes()

    return API.get('baseURL', `/${loggedInUserDesignation}/installations`, {
      queryStringParameters: {
        transformerId,
        month,
        page,
        perPage,
        ...(rrNumber && { RRNumber: rrNumber }),
        count: true,
        location: locationName,
        locationCode
      }
    })
  }

  // When a user in hierarchy is selected by CE-AEE
  const {
    location: { areaCode, name: locationName }
  } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())

  return API.get('baseURL', `/${loggedInUserDesignation}/installations`, {
    queryStringParameters: {
      transformerId,
      month,
      page,
      perPage,
      ...(rrNumber && { RRNumber: rrNumber }),
      count: true,
      location: locationName,
      locationCode: selectedHierarchy.location[areaCode]
    }
  })
}

export const filterInstallations = (
  transformerId,
  month,
  page,
  perPage,
  billingStatus,
  tcStatus,
  variant,
  rrNumber,
  selectedHierarchy
) => {
  const queryStringParameters = {
    transformerId,
    month,
    page,
    perPage,
    ...(rrNumber && { RRNumber: rrNumber }),
    ...(billingStatus.length > 0 && { billingStatus }),
    ...(tcStatus.length > 0 && { untagged: tcStatus[0] === 'untagged' }),
    ...(variant && { [variant]: true })
  }
  const loggedInUserDesignation = fetchUserDesignation()
  if (loggedInUserDesignation === 'ae') {
    return API.get('baseURL', '/ae/installations', {
      queryStringParameters
    })
  }

  // When CIO / DT makes an API call
  if (loggedInUserDesignation === 'dt' || loggedInUserDesignation === 'cio') {
    // If no user in hierarchy is selected by DT/CIO
    if (selectedHierarchy === 'All') {
      return API.get('baseURL', `/${loggedInUserDesignation}/installations`, {
        queryStringParameters: {
          ...queryStringParameters
        }
      })
    }

    // When a user in hierarchy is selected by DT/CIO
    const {
      location: { areaCode, name: locationName }
    } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())

    return API.get('baseURL', `/${loggedInUserDesignation}/installations`, {
      queryStringParameters: {
        ...queryStringParameters,
        location: locationName,
        locationCode: selectedHierarchy.location[areaCode]
      }
    })
  }

  // When CE-AEE makes an API call

  // When no user is selected in hierarchy by CE-AEE
  if (selectedHierarchy === 'All') {
    const {
      areaCode: cognitoAreaCode,
      location: { name: locationName }
    } = getHierarchyDataByProperty('designation', loggedInUserDesignation)
    const { [`custom:${cognitoAreaCode}`]: locationCode } = fetchUserAttributes()

    return API.get('baseURL', `/${loggedInUserDesignation}/installations`, {
      queryStringParameters: {
        ...queryStringParameters,
        location: locationName,
        locationCode
      }
    })
  }

  // When a user in hierarchy is selected by CE-AEE
  const {
    location: { areaCode, name: locationName }
  } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())

  return API.get('baseURL', `/${loggedInUserDesignation}/installations`, {
    queryStringParameters: {
      ...queryStringParameters,
      location: locationName,
      locationCode: selectedHierarchy.location[areaCode]
    }
  })
}

export const deleteTC = (transformerId, monthYear) => {
  return API.del('baseURL', `/ae/transformer-centres/${transformerId}`, {
    queryStringParameters: {
      month: monthYear
    }
  })
}

export const addTC = (body) => {
  return API.post('baseURL', '/ae/transformer-centres', {
    body
  })
}

export const editTC = (body, transformerId, monthYear) => {
  return API.patch('baseURL', `/ae/transformer-centres/${transformerId}`, {
    queryStringParameters: {
      month: monthYear
    },
    body
  })
}

export const searchFeeder = (number) => {
  return API.get('baseURL', '/ae/feeders', {
    queryStringParameters: {
      number
      // tagged: true
    }
  })
}

export const exportTCs = (
  month,
  number,
  readingDay,
  mrCode,
  selectedHierarchy,
  selectedDownloadOption
) => {
  const loggedInUserDesignation = fetchUserDesignation()
  const queryStringParameters = {
    month,
    ...(number && { number }),
    ...(readingDay.length >= 1 && { readingDay }),
    ...(mrCode.length >= 1 && { mrCode }),
    ...(selectedDownloadOption === 'feeder-wise' && { feederWise: true })
  }
  if (loggedInUserDesignation === 'ae') {
    return API.get('baseURL', '/ae/transformer-centres/export', {
      queryStringParameters
    })
  }

  // When CIO / DT makes an API call
  if (loggedInUserDesignation === 'dt' || loggedInUserDesignation === 'cio') {
    // If no user in hierarchy is selected by DT/CIO
    if (selectedHierarchy === 'All') {
      return API.get('baseURL', `/${loggedInUserDesignation}/transformer-centres/export`, {
        queryStringParameters: {
          ...queryStringParameters
        }
      })
    }

    // When a user in hierarchy is selected by DT/CIO
    const {
      location: { areaCode, name: locationName }
    } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())

    return API.get('baseURL', `/${loggedInUserDesignation}/transformer-centres/export`, {
      queryStringParameters: {
        ...queryStringParameters,
        location: locationName,
        locationCode: selectedHierarchy.location[areaCode]
      }
    })
  }

  // When CE-AEE makes an API call

  // When no user is selected in hierarchy by CE-AEE
  if (selectedHierarchy === 'All') {
    const {
      areaCode: cognitoAreaCode,
      location: { name: locationName }
    } = getHierarchyDataByProperty('designation', loggedInUserDesignation)

    const { [`custom:${cognitoAreaCode}`]: locationCode } = fetchUserAttributes()
    return API.get('baseURL', `/${loggedInUserDesignation}/transformer-centres/export`, {
      queryStringParameters: {
        ...queryStringParameters,
        location: locationName,
        locationCode
      }
    })
  }

  // When a user in hierarchy is selected by CE-AEE
  const {
    location: { areaCode, name: locationName }
  } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())

  return API.get('baseURL', `/${loggedInUserDesignation}/transformer-centres/export`, {
    queryStringParameters: {
      ...queryStringParameters,
      location: locationName,
      locationCode: selectedHierarchy.location[areaCode]
    }
  })
}

// This function needs to be moved to installations file
export const getLossAnalysisMetrics = (transformerId, month, selectedHierarchy) => {
  const loggedInUserDesignation = fetchUserDesignation()

  // When CIO / DT makes an API call
  if (loggedInUserDesignation === 'dt' || loggedInUserDesignation === 'cio') {
    // If no user in hierarchy is selected by DT/CIO
    if (selectedHierarchy === 'All') {
      return API.get('baseURL', `/${loggedInUserDesignation}/installations/counts/months/${month}`, {
        queryStringParameters: {
          transformerId
        }
      })
    }

    // When a user in hierarchy is selected by DT/CIO
    const {
      location: { areaCode, name: locationName }
    } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())
    return API.get('baseURL', `/${loggedInUserDesignation}/installations/counts/months/${month}`, {
      queryStringParameters: {
        transformerId,
        location: locationName,
        locationCode: selectedHierarchy.location[areaCode]
      }
    })
  }

  // When CE-AEE makes an API call

  // When no user is selected in hierarchy by CE-AE
  if (selectedHierarchy === 'All') {
    const {
      areaCode: cognitoAreaCode,
      location: { name: locationName }
    } = getHierarchyDataByProperty('designation', loggedInUserDesignation)
    const { [`custom:${cognitoAreaCode}`]: locationCode } = fetchUserAttributes()

    return API.get('baseURL', `/${loggedInUserDesignation}/installations/counts/months/${month}`, {
      queryStringParameters: {
        transformerId,
        location: locationName,
        locationCode
      }
    })
  }

  // When a user in hierarchy is selected by CE-AE
  const {
    location: { areaCode, name: locationName }
  } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())
  return API.get('baseURL', `/${loggedInUserDesignation}/installations/counts/months/${month}`, {
    queryStringParameters: {
      transformerId,
      location: locationName,
      locationCode: selectedHierarchy.location[areaCode]
    }
  })
}
