import { API } from 'aws-amplify'
import { perPageCount } from '../../assets/offlineData/apiCallDetails'
import { fetchUserAttributes, fetchUserDesignation } from '../utils/meta'
import { getHierarchyDataByProperty } from '../utils/hierarchy'

export const getBillingCount = (month, selectedHierarchy, category) => {
  const loggedInUserDesignation = fetchUserDesignation()

  // When CIO / DT makes an API call
  if (loggedInUserDesignation === 'dt' || loggedInUserDesignation === 'cio') {
    // If no user in hierarchy is selected by DT/CIO
    if (selectedHierarchy === 'All') {
      return API.get('baseURL', `/${loggedInUserDesignation}/installations/counts/months/${month}`, {
        queryStringParameters: {
          category
        }
      })
    }

    // When a user in hierarchy is selected by DT/CIO
    const {
      location: { areaCode, name: locationName }
    } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())
    return API.get('baseURL', `/${loggedInUserDesignation}/installations/counts/months/${month}`, {
      queryStringParameters: {
        location: locationName,
        locationCode: selectedHierarchy.location[areaCode],
        category
      }
    })
  }

  // When CE-AEE makes an API call

  // When no user is selected in hierarchy by CE-AE
  if (selectedHierarchy === 'All') {
    const {
      areaCode: cognitoAreaCode,
      location: { name: locationName }
    } = getHierarchyDataByProperty('designation', loggedInUserDesignation)
    const { [`custom:${cognitoAreaCode}`]: locationCode } = fetchUserAttributes()

    return API.get('baseURL', `/${loggedInUserDesignation}/installations/counts/months/${month}`, {
      queryStringParameters: {
        location: locationName,
        locationCode,
        category
      }
    })
  }

  // When a user in hierarchy is selected by CE-AE
  const {
    location: { areaCode, name: locationName }
  } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())
  return API.get('baseURL', `/${loggedInUserDesignation}/installations/counts/months/${month}`, {
    queryStringParameters: {
      location: locationName,
      locationCode: selectedHierarchy.location[areaCode],
      category
    }
  })
}

export const getInstallations = (
  monthYear,
  currentPage,
  untagged,
  RRNumber,
  readingDay,
  mrCode,
  tariff = [],
  remark = [],
  duration = [],
  selectedHierarchy,
  taggingFilters = []
) => {
  const loggedInUserDesignation = fetchUserDesignation()
  const queryStringParameters = {
    month: monthYear,
    page: currentPage,
    perPage: perPageCount,
    ...(untagged && { untagged }),
    ...(RRNumber && { RRNumber }),
    ...(readingDay && { readingDay }),
    ...(mrCode && { mrCode }),
    ...(duration.length >= 1 && { from: duration }),
    ...(tariff.length >= 1 && { tariff }),
    ...(remark.length >= 1 && { billingStatus: remark }),
    ...((taggingFilters.length >= 1 && !taggingFilters.includes('untagged')) && {
      taggedBy: taggingFilters
    }),
    ...((taggingFilters.length === 1 && taggingFilters?.[0] === 'untagged') &&
             { untagged: true })
  }

  if (loggedInUserDesignation === 'ae') {
    return API.get('baseURL', '/ae/installations', {
      queryStringParameters
    })
  }

  // When CIO / DT makes an API call
  if (loggedInUserDesignation === 'dt' || loggedInUserDesignation === 'cio') {
    // If no user in hierarchy is selected by DT/CIO
    if (selectedHierarchy === 'All') {
      return API.get('baseURL', `/${loggedInUserDesignation}/installations`, {
        queryStringParameters: {
          ...queryStringParameters
        }
      })
    }

    // When a user in hierarchy is selected by DT/CIO
    const {
      location: { areaCode, name: locationName }
    } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())
    return API.get('baseURL', `/${loggedInUserDesignation}/installations`, {
      queryStringParameters: {
        ...queryStringParameters,
        location: locationName,
        locationCode: selectedHierarchy.location[areaCode]
      }
    })
  }

  // When CE-AEE makes an API call

  // When no user is selected in hierarchy by CE-AEE
  if (selectedHierarchy === 'All') {
    const {
      areaCode: cognitoAreaCode,
      location: { name: locationName }
    } = getHierarchyDataByProperty('designation', loggedInUserDesignation)
    const { [`custom:${cognitoAreaCode}`]: locationCode } = fetchUserAttributes()

    return API.get('baseURL', `/${loggedInUserDesignation}/installations`, {
      queryStringParameters: {
        ...queryStringParameters,
        location: locationName,
        locationCode
      }
    })
  }

  // When a user in hierarchy is selected by CE-AEE
  const {
    location: { areaCode, name: locationName }
  } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())
  return API.get('baseURL', `/${loggedInUserDesignation}/installations`, {
    queryStringParameters: {
      ...queryStringParameters,
      location: locationName,
      locationCode: selectedHierarchy.location[areaCode]
    }
  })
}

// export const getInstallationsByMrCodeAndReadingDay = (
//   readingDay,
//   monthYear,
//   currentPage,
//   untagged,
//   mrCode,
//   selectedHierarchy
// ) => {
//   const queryStringParameters = {
//     ...(readingDay && { readingDay }),
//     month: monthYear,
//     page: currentPage,
//     perPage: perPageCount,
//     ...(untagged && { untagged }),
//     ...(mrCode && { mrCode })
//   }

//   const loggedInUserDesignation = fetchUserDesignation()

//   if (loggedInUserDesignation === 'ae') {
//     return API.get('baseURL', '/ae/installations', {
//       queryStringParameters
//     })
//   }

//   if (selectedHierarchy === 'All') {
//     const {
//       areaCode: cognitoAreaCode,
//       location: { name: locationName }
//     } = getHierarchyDataByProperty('designation', loggedInUserDesignation)
//     const { [`custom:${cognitoAreaCode}`]: locationCode } = fetchUserAttributes()

//     return API.get('baseURL', `/${loggedInUserDesignation}/installations`, {
//       queryStringParameters: {
//         ...queryStringParameters,
//         ...(loggedInUserDesignation !== 'dt' && { location: locationName }),
//         ...(loggedInUserDesignation !== 'dt' && { locationCode })
//       }
//     })
//   }

//   const {
//     location: { areaCode, name: locationName }
//   } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())
//   return API.get('baseURL', `/${loggedInUserDesignation}/installations`, {
//     queryStringParameters: {
//       ...queryStringParameters,
//       location: locationName,
//       locationCode: selectedHierarchy.location[areaCode]
//     }
//   })
// }

// export const filterInstallations = (
//   monthYear,
//   currentPage,
//   duration,
//   tariff,
//   untagged,
//   remark,
//   selectedHierarchy
// ) => {
//   const queryStringParameters = {
//     month: monthYear,
//     page: currentPage,
//     perPage: perPageCount,
//     ...(untagged && { untagged }),
//     ...(duration.length >= 1 && { from: duration }),
//     ...(tariff.length >= 1 && { tariff }),
//     ...(remark.length >= 1 && { billingStatus: remark })
//   }
//   const loggedInUserDesignation = fetchUserDesignation()
//   if (loggedInUserDesignation === 'ae') {
//     return API.get('baseURL', '/ae/installations', {
//       queryStringParameters
//     })
//   }

//   // When CIO / DT makes an API call
//   if (loggedInUserDesignation === 'dt' || loggedInUserDesignation === 'cio') {
//     // If no user in hierarchy is selected by DT/CIO
//     if (selectedHierarchy === 'All') {
//       return API.get('baseURL', `/${loggedInUserDesignation}/installations`, {
//         queryStringParameters: {
//           ...queryStringParameters
//         }
//       })
//     }

//     // When a user in hierarchy is selected by DT/CIO
//     const {
//       location: { areaCode, name: locationName }
//     } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())
//     return API.get('baseURL', `/${loggedInUserDesignation}/installations`, {
//       queryStringParameters: {
//         ...queryStringParameters,
//         location: locationName,
//         locationCode: selectedHierarchy.location[areaCode]
//       }
//     })
//   }

//   // When CE-AEE makes an API call

//   // When no user is selected in hierarchy by CE-AEE
//   if (selectedHierarchy === 'All') {
//     const {
//       areaCode: cognitoAreaCode,
//       location: { name: locationName }
//     } = getHierarchyDataByProperty('designation', loggedInUserDesignation)
//     const { [`custom:${cognitoAreaCode}`]: locationCode } = fetchUserAttributes()

//     return API.get('baseURL', `/${loggedInUserDesignation}/installations`, {
//       queryStringParameters: {
//         ...queryStringParameters,
//         location: locationName,
//         locationCode
//       }
//     })
//   }

//   // When a user in hierarchy is selected by CE-AEE

//   const {
//     location: { areaCode, name: locationName }
//   } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())
//   return API.get('baseURL', `/${loggedInUserDesignation}/installations`, {
//     queryStringParameters: {
//       ...queryStringParameters,
//       location: locationName,
//       locationCode: selectedHierarchy.location[areaCode]
//     }
//   })
// }

export const getOtherInstallations = (
  monthYear,
  currentPage,
  RRNumber,
  billingStatus,
  readingDay,
  mrCode,
  duration = [],
  tariff = [],
  remark = [],
  variant = '',
  selectedHierarchy
) => {
  const queryStringParameters = {
    month: monthYear,
    page: currentPage,
    ...(RRNumber && { RRNumber }),
    ...(billingStatus && { billingStatus }),
    perPage: perPageCount,
    ...(readingDay && { readingDay }),
    ...(mrCode && { mrCode }),
    ...(duration.length >= 1 && { from: duration }),
    ...(tariff.length >= 1 && { tariff }),
    ...(remark.length >= 1 && { billingRemark: remark }),
    ...(variant && { [variant]: true })
  }
  const loggedInUserDesignation = fetchUserDesignation()
  if (loggedInUserDesignation === 'ae') {
    return API.get('baseURL', '/ae/installations', {
      queryStringParameters
    })
  }

  // When CIO / DT makes an API call
  if (loggedInUserDesignation === 'dt' || loggedInUserDesignation === 'cio') {
    // If no user in hierarchy is selected by DT/CIO
    if (selectedHierarchy === 'All') {
      return API.get('baseURL', `/${loggedInUserDesignation}/installations`, {
        queryStringParameters: {
          ...queryStringParameters
        }
      })
    }

    // When a user in hierarchy is selected by DT/CIO
    const {
      location: { areaCode, name: locationName }
    } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())
    return API.get('baseURL', `/${loggedInUserDesignation}/installations`, {
      queryStringParameters: {
        ...queryStringParameters,
        location: locationName,
        locationCode: selectedHierarchy.location[areaCode]
      }
    })
  }

  // When CE-AEE makes an API call

  // When no user is selected in hierarchy by CE-AEE
  if (selectedHierarchy === 'All') {
    const {
      areaCode: cognitoAreaCode,
      location: { name: locationName }
    } = getHierarchyDataByProperty('designation', loggedInUserDesignation)
    const { [`custom:${cognitoAreaCode}`]: locationCode } = fetchUserAttributes()

    return API.get('baseURL', `/${loggedInUserDesignation}/installations`, {
      queryStringParameters: {
        ...queryStringParameters,
        location: locationName,
        locationCode
      }
    })
  }

  // When a user in hierarchy is selected by CE-AEE
  const {
    location: { areaCode, name: locationName }
  } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())
  return API.get('baseURL', `/${loggedInUserDesignation}/installations`, {
    queryStringParameters: {
      ...queryStringParameters,
      location: locationName,
      locationCode: selectedHierarchy.location[areaCode]
    }
  })
}

// export const getOtherInstallationsByMrCodeAndReadingDay = (
//   readingDay,
//   monthYear,
//   currentPage,
//   mrCode,
//   billingStatus,
//   selectedHierarchy
// ) => {
//   const queryStringParameters = {
//     ...(readingDay && { readingDay }),
//     month: monthYear,
//     page: currentPage,
//     ...(mrCode && { mrCode }),
//     billingStatus,
//     perPage: perPageCount
//   }
//   const loggedInUserDesignation = fetchUserDesignation()
//   if (loggedInUserDesignation === 'ae') {
//     return API.get('baseURL', '/ae/installations', {
//       queryStringParameters
//     })
//   }

//   if (selectedHierarchy === 'All') {
//     const {
//       areaCode: cognitoAreaCode,
//       location: { name: locationName }
//     } = getHierarchyDataByProperty('designation', loggedInUserDesignation)
//     const { [`custom:${cognitoAreaCode}`]: locationCode } = fetchUserAttributes()

//     return API.get('baseURL', `/${loggedInUserDesignation}/installations`, {
//       queryStringParameters: {
//         ...queryStringParameters,
//         ...(loggedInUserDesignation !== 'dt' && { location: locationName }),
//         ...(loggedInUserDesignation !== 'dt' && { locationCode })
//       }
//     })
//   }

//   const {
//     location: { areaCode, name: locationName }
//   } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())
//   return API.get('baseURL', `/${loggedInUserDesignation}/installations`, {
//     queryStringParameters: {
//       ...queryStringParameters,
//       location: locationName,
//       locationCode: selectedHierarchy.location[areaCode]
//     }
//   })
// }

// export const filterOtherInstallations = (
//   monthYear,
//   currentPage,
//   duration,
//   tariff,
//   remark,
//   billingStatus,
//   selectedHierarchy
// ) => {
//   const queryStringParameters = {
//     month: monthYear,
//     page: currentPage,
//     ...(duration.length >= 1 && { from: duration }),
//     ...(tariff.length >= 1 && { tariff }),
//     ...(remark.length >= 1 && { billingRemark: remark }),
//     billingStatus,
//     perPage: perPageCount
//   }

//   const loggedInUserDesignation = fetchUserDesignation()
//   if (loggedInUserDesignation === 'ae') {
//     return API.get('baseURL', '/ae/installations', {
//       queryStringParameters
//     })
//   }

//   if (selectedHierarchy === 'All') {
//     const {
//       areaCode: cognitoAreaCode,
//       location: { name: locationName }
//     } = getHierarchyDataByProperty('designation', loggedInUserDesignation)
//     const { [`custom:${cognitoAreaCode}`]: locationCode } = fetchUserAttributes()

//     return API.get('baseURL', `/${loggedInUserDesignation}/installations`, {
//       queryStringParameters: {
//         ...queryStringParameters,
//         ...(loggedInUserDesignation !== 'dt' && { location: locationName }),
//         ...(loggedInUserDesignation !== 'dt' && { locationCode })
//       }
//     })
//   }

//   const {
//     location: { areaCode, name: locationName }
//   } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())
//   return API.get('baseURL', `/${loggedInUserDesignation}/installations`, {
//     queryStringParameters: {
//       ...queryStringParameters,
//       location: locationName,
//       locationCode: selectedHierarchy.location[areaCode]
//     }
//   })
// }

export const getTCs = (readingDay, page, perPage, month, number, mrCode, location, locationCode) => {
  return API.get('baseURL', '/ae/transformer-centres', {
    queryStringParameters: {
      ...(readingDay && { readingDay }),
      page,
      perPage,
      month,
      ...(number && { number }),
      ...(mrCode && { mrCode }),
      ...(location && { location }),
      ...(locationCode && { locationCode })
    }
  })
}

export const tagInstallation = (tcId, installationId, month) => {
  return API.patch(
    'baseURL',
    `/ae/transformer-centres/${tcId}/installations/${installationId}/tag`,
    {
      queryStringParameters: {
        month
      }
    }
  )
}

export const exportInstallations = (
  monthYear,
  untagged,
  RRNumber,
  readingDay,
  mrCode,
  tariff = [],
  remark = [],
  selectedHierarchy
) => {
  const queryStringParameters = {
    month: monthYear,
    ...{ untagged },
    ...(RRNumber && { RRNumber }),
    ...(readingDay && { readingDay }),
    ...(mrCode && { mrCode }),
    // ...(duration.length >= 1 && { 'from': duration }),
    ...(tariff.length >= 1 && { tariff }),
    ...(remark.length >= 1 && { billingStatus: remark })
  }

  const loggedInUserDesignation = fetchUserDesignation()
  if (loggedInUserDesignation === 'ae') {
    return API.get('baseURL', `/${loggedInUserDesignation}/installations/export`, {
      queryStringParameters
    })
  }

  // When CIO / DT makes an API call
  if (loggedInUserDesignation === 'dt' || loggedInUserDesignation === 'cio') {
    // If no user in hierarchy is selected by DT/CIO
    if (selectedHierarchy === 'All') {
      return API.get('baseURL', `/${loggedInUserDesignation}/installations/export`, {
        queryStringParameters: {
          ...queryStringParameters
        }
      })
    }

    // When a user in hierarchy is selected by DT/CIO
    const {
      location: { areaCode, name: locationName }
    } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())
    return API.get('baseURL', `/${loggedInUserDesignation}/installations/export`, {
      queryStringParameters: {
        ...queryStringParameters,
        location: locationName,
        locationCode: selectedHierarchy.location[areaCode]
      }
    })
  }

  // When CE-AEE makes an API call

  // When no user is selected in hierarchy by CE-AEE
  if (selectedHierarchy === 'All') {
    const {
      areaCode: cognitoAreaCode,
      location: { name: locationName }
    } = getHierarchyDataByProperty('designation', loggedInUserDesignation)
    const { [`custom:${cognitoAreaCode}`]: locationCode } = fetchUserAttributes()

    return API.get('baseURL', `/${loggedInUserDesignation}/installations/export`, {
      queryStringParameters: {
        ...queryStringParameters,
        location: locationName,
        locationCode
      }
    })
  }

  // When a user in hierarchy is selected by CE-AEE
  const {
    location: { areaCode, name: locationName }
  } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())
  return API.get('baseURL', `/${loggedInUserDesignation}/installations/export`, {
    queryStringParameters: {
      ...queryStringParameters,
      location: locationName,
      locationCode: selectedHierarchy.location[areaCode]
    }
  })

  // return API.get('baseURL', `/ae/installations/export`, {
  //   queryStringParameters,
  // })
}
