import React, { createContext, useState } from 'react'
import { DateToYYYYMM } from '../services/utils/date'

export const AuditMonthContext = createContext()

const AuditMonthProvider = ({ children }) => {
  const initialMonth = window.localStorage.getItem('auditMonth')
  const [auditMonth, setAuditMonthYear] = useState(initialMonth ? new Date(initialMonth) : new Date())
  const [mrCode, setMrCode] = useState([])

  // useEffect(() => {
  //   setAuditMonthYear()
  // }, [])

  const auditMonthHandler = (auditMonth) => {
    setAuditMonthYear(auditMonth)
    window.localStorage.setItem('auditMonth', auditMonth)
  }

  return (
    <AuditMonthContext.Provider value={{
      auditMonth,
      setAuditMonth: auditMonthHandler,
      auditMonthYear: DateToYYYYMM(auditMonth),
      mrCode,
      setMrCode
    }}
    >
      {children}
    </AuditMonthContext.Provider>
  )
}

export default AuditMonthProvider
