import React, { useEffect, useState, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import meterImage from '../../assets/img/meter.svg'
import doorlockImage from '../../assets/img/doorlock.png'
import newInstallationImage from '../../assets/img/new-installation.png'
import unbilledImage from '../../assets/img/unbilled.png'
import abnormalImage from '../../assets/img/abnormal.png'
import subnormalImage from '../../assets/img/subnormal.png'

import vacantImage from '../../assets/img/vacant.png'
import zerpConsumptionImage from '../../assets/img/zerp-consumption.svg'
import mnrImage from '../../assets/img/mnr.svg'
import MyInstallations from './Tables/MyInstallations'
import NewInstallations from './Tables/NewInstallations'
import Unbilled from './Tables/Unbilled'
import Vacant from './Tables/Vacant'
import ZeroConsumption from './Tables/ZeroConsumption'
import MNR from './Tables/MNR'
import Doorlock from './Tables/Doorlock'
import { getBillingCount } from '../../services/api/billingService'
import { AuditMonthContext } from '../../context/AuditMonthContext'
import { NotifierContext } from '../../context/NotifierContext'
// import { MrCodeContext } from '../../context/MrCodeContext'
import { getMRs } from '../../services/api/mrService'
import Abnormal from './Tables/Abnormal'
import Subnormal from './Tables/Subnormal'
import { fetchUserDesignation } from '../../services/utils/meta'
import { HierarchyContext } from '../../context/HierarchyContext'

function useQuery () {
  return new URLSearchParams(useLocation().search)
}

const List = (props) => {
  const { auditMonthYear, setMrCode } = useContext(AuditMonthContext)
  const notifier = useContext(NotifierContext)
  const { selectedHierarchy } = useContext(HierarchyContext)

  const loggedInUserDesignation = fetchUserDesignation()
  const categoriesData = [
    {
      name: 'All Installations',
      slug: 'my_installations',
      counts: '0',
      icon: meterImage,
      disabled: false,
      backendCountEnum: 'all'
    },
    {
      name: 'Untagged Installations',
      slug: 'new_installations',
      counts: '0',
      icon: newInstallationImage,
      disabled: false,
      backendCountEnum: 'untagged'
    },
    {
      name: 'Unbilled',
      slug: 'unbilled',
      counts: '0',
      icon: unbilledImage,
      disabled: false,
      backendCountEnum: 'unbilled'
    },
    {
      name: 'Vacant',
      slug: 'vacant',
      counts: '0',
      icon: vacantImage,
      disabled: false,
      backendCountEnum: 'vacant'
    },
    {
      name: 'Zero Consumption',
      slug: 'zero_consumption',
      counts: '0',
      icon: zerpConsumptionImage,
      disabled: false,
      backendCountEnum: 'zeroConsumption'
    },
    {
      name: 'MNR',
      slug: 'mnr',
      counts: '0',
      icon: mnrImage,
      disabled: false,
      backendCountEnum: 'mnr'
    },
    {
      name: 'Doorlock',
      slug: 'doorlock',
      counts: '0',
      icon: doorlockImage,
      disabled: false,
      backendCountEnum: 'doorLock'
    },
    {
      name: 'Abnormal',
      slug: 'abnormal',
      counts: '0',
      icon: abnormalImage,
      disabled: false,
      backendCountEnum: 'abnormal'
    },
    {
      name: 'Subnormal',
      slug: 'subnormal',
      counts: '0',
      icon: subnormalImage,
      disabled: false,
      backendCountEnum: 'subnormal'
    }
  ]
  const [categoriesArray, setCategoriesArray] = useState(categoriesData)
  // const [loading, setLoading] = useState(true);
  const [countLoader, setCountLoader] = useState(['all', 'untagged', 'unbilled', 'vacant', 'doorlock', 'mnr', 'zeroConsumption', 'abnormal', 'subnormal'])

  useEffect(() => {
    categoriesData.forEach(item => {
      fetchBillingCount(item.backendCountEnum)
    })
    loggedInUserDesignation === 'ae' && fetchMrCodes()
  }, [auditMonthYear, selectedHierarchy])

  const fetchBillingCount = async (category) => {
    try {
      setCountLoader(prevState => [...prevState, category])
      const response = await getBillingCount(auditMonthYear, selectedHierarchy, category)
      const categoriesArrayCopy = [...categoriesArray]
      const { data } = response

      const objToUpdate = categoriesArrayCopy.find(item => item.backendCountEnum === category)
      objToUpdate.counts = data

      setCategoriesArray(categoriesArrayCopy)
      // setLoading(false)
    } catch (err) {
      notifier.notifyError(err)
    } finally {
      setCountLoader(prevArray => prevArray.filter(item => item !== category))
    }
  }

  const fetchMrCodes = async () => {
    try {
      const response = await getMRs('', 1, 100)
      const mrCodes = response.data.MRList.map((item) => item.userInfo.mrCode)
      setMrCode(mrCodes)
    } catch (err) {
      notifier.notifyError(err)
    }
  }

  const components = {
    my_installations: MyInstallations,
    new_installations: NewInstallations,
    unbilled: Unbilled,
    vacant: Vacant,
    zero_consumption: ZeroConsumption,
    mnr: MNR,
    doorlock: Doorlock,
    abnormal: Abnormal,
    subnormal: Subnormal
  }

  const query = useQuery()
  const history = useHistory()

  const slug = query.get('tab') || 'my_installations'

  const ComponentName = components[query.get('tab') || 'my_installations']

  const [categories] = useState(categoriesArray)

  const redirectHandler = (slug) => {
    history.push('/billing?tab=' + slug)
  }

  return (
    <>
      <div className='my-installation-content'>
        <div className='container pl-0 pt-0 pb-0'>
          <div className='row'>
            <div className='col-md-4 col-lg-3'>
              <div className='tc-audit-no-list'>
                {categories.map((category, index) => {
                  return (
                    <div
                      key={index}
                      data-bs-toggle='tooltip' data-bs-placement='top' title={category.disabled}
                      style={{ cursor: category.disabled ? 'not-allowed' : 'pointer' }}
                    >
                      <div
                        style={{ pointerEvents: category.disabled ? 'none' : 'auto' }}
                        onClick={() => redirectHandler(category.slug)}
                        className={`tc-audit-no-collapse ${
                          slug === category.slug ? 'active' : ''
                        } pt-3 pb-3 ps-2 pe-2`}
                      >
                        <card className='collapse-text' style={{ opacity: category.disabled ? 0.2 : 1 }}>
                          <span className='iconbox'>
                            <img
                              className='img-fluid'
                              src={category.icon}
                              alt='missing icon'
                            />
                          </span>
                          <div className='mb-0 tc-audit-no-title ms-2 w-100'>
                            <p className='mb-1 tc-no'>{category.name}</p>
                            {countLoader.includes(category.backendCountEnum)
                              ? (
                                <div
                                  className='spinner-grow spinner-grow-sm'
                                  role='status'
                                >
                                  <span className='visually-hidden'>Loading...</span>
                                </div>
                                )
                              : (
                                <p className='mb-0 tc-no-text'>{category?.counts?.toLocaleString('en-IN')}</p>
                                )}
                          </div>
                        </card>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            <ComponentName onTagTC={() => {
              ['all', 'untagged'].forEach(item => {
                fetchBillingCount(item)
              })
            }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default List
