import { API } from 'aws-amplify'
import { fetchUserDesignation } from '../utils/meta'
// import { hierarchyDetails } from '../../assets/offlineData/hierarchyData'

/// ///////////////////////////////////////////////////////Feeder APIS////////////////////////////////////////////////
export const getFeeders = (pageNumber, selectedHierarchy, mdmCode, selectedFilter) => {
  const queryParameters = {
    page: pageNumber,
    perPage: 10,
    ...(mdmCode.length > 0 && { mdmCode })

  }

  const loggedInUserDesignation = fetchUserDesignation()

  if (loggedInUserDesignation === 'ae') {
    return API.get('baseURL', `/${loggedInUserDesignation}/feeders`, {
      queryStringParameters: {
        // tagged: true,
        // ...(selectedFilter !== '' && { tagged: selectedFilter === 'tagged' ? true : '' }),
        ...queryParameters
      }
    })
  }
  return API.get('baseURL', `/${loggedInUserDesignation}/feeders`, {
    queryStringParameters: {
      ...queryParameters
    }
  })
}

export const searchFeeders = (mdmCode, stationCode) => {
  return API.get('baseURL', '/ae/feeders', {
    queryStringParameters: {
      page: 1,
      perPage: 100,
      mdmCode,
      stationCode
    }
  })
}

export const tagAEToFeeder = (stationCode, mdmCode) => {
  return API.patch('baseURL', `/ae/power-stations/${stationCode}/feeders/${mdmCode}/tag`)
}
