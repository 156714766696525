import React, { useContext, useEffect, useState } from 'react'
import { NotifierContext } from '../../context/NotifierContext'
import { getFeeders, tagAEToFeeder } from '../../services/api/feederService'
import { checkIfUserIsAE, fetchSectionAndDivisionCode } from '../../services/utils/meta'
import NoDataFound from '../../components/atoms/NoDataFound'
import { DateToDDMMYYYY } from '../../services/utils/date'
import { HierarchyContext } from '../../context/HierarchyContext'
import styles from './feeders.module.scss'
import closeIcon from '../../assets/img/close-icon@2x.png'
import Paginator from '../../components/molecules/Paginator'

export default function Listing () {
  const notifier = useContext(NotifierContext)

  const [feeders, setFeeders] = useState([])
  const [feederLoader, setFeederLoader] = useState(true)
  const [filterLoader, setFilterLoader] = useState(false)
  const [tagButtonLoader, setTagButtonLoader] = useState(false)
  const [feederPageData, setFeederPageData] = useState({
    lastPage: 0,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
    limit: 20,
    skip: 0
  })
  const [searchString, setSearchString] = useState('')
  const [selectedFilter, setSelectedFilter] = useState('')
  const [sectionCode, setSectionCode] = useState('----')
  const [subDivisionCode, setSubDivisionCode] = useState('----')

  const { selectedHierarchy } = useContext(HierarchyContext)

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchFeeders(1, selectedFilter)
    }, 500)
    return () => {
      clearTimeout(timer)
    }
  }, [selectedHierarchy, searchString])

  useEffect(() => {
    fetchMetaData()
  }, [])

  const fetchMetaData = async () => {
    try {
      const sectionCode = await fetchSectionAndDivisionCode('sectionCode')
      const subDivisionCode = await fetchSectionAndDivisionCode('subDivisionCode')
      setSectionCode(sectionCode)
      setSubDivisionCode(subDivisionCode)
    } catch (err) {
      notifier.notifyError(err)
    }
  }

  // Logic to toggle accordian i.e, open and close accordian and load feeders from api.
  const fetchFeeders = async (pageNumber, filterValue) => {
    try {
      setFeederLoader(true)
      const response = await getFeeders(pageNumber, selectedHierarchy, searchString, filterValue)
      setFeeders(response.data.feederList)
      setFeederPageData(response.data.pageData)
    } catch (err) {
      notifier.notifyError(err)
    } finally {
      setFeederLoader(false)
    }
  }

  // const handleFeederPagination = async () => {
  //   try {
  //     setFeederLoader(true)
  //     const response = await getFeeders(feederPageData.currentPage + 1, selectedHierarchy)
  //     setFeeders([...feeders, ...response.data.feederList])
  //     setFeederPageData(response.data.pageData)
  //   } catch (err) {
  //     notifier.notifyError(err)
  //   } finally {
  //     setFeederLoader(false)
  //   }
  //   // console.log(selectedStationDetails.stationCode)
  // }

  const handleFeederTagging = async (stationCode, mdmCode) => {
    try {
      setTagButtonLoader(mdmCode)
      await tagAEToFeeder(stationCode, mdmCode)
      notifier.notifySuccess('Feeder successfully tagged to AE')
      fetchFeeders(1, '')
    } catch (error) {
      notifier.notifyError(error)
    } finally {
      setTagButtonLoader(false)
    }
  }
  const handleFeederUnTagging = () => {
    try {
      window.alert('Coming Soon!')
    } catch (error) {

    }
    //  finally {

    // }
  }

  const handleFeederFilter = async (filterValue) => {
    try {
      if (filterValue === 'untagged') {
        window.alert('Coming Soon!')
        return
      }
      const modifiedFilterValue = selectedFilter === filterValue ? '' : filterValue
      setFilterLoader(filterValue)
      await fetchFeeders(1, modifiedFilterValue)
      setSelectedFilter(modifiedFilterValue)
    } catch (err) {
      notifier.notifyError(err)
    } finally {
      setFilterLoader(false)
    }
  }
  const isUserAE = checkIfUserIsAE()

  return (
    <>
      <div className='feeder-management-content'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-12 col-md-6'>
              <h1 className='mb-0'>Feeder Management</h1>
            </div>
          </div>
          <div className={` ${isUserAE ? '' : 'mt-4'}`} />
          <div className={`row ${isUserAE ? 'd-block' : 'd-none'}`}>
            <div className='col-md-12 '>
              <hr className='horizontal-line' />
              <div className='code-section'>
                <span>
                  Section Code: <span className='font-medium'>{sectionCode}</span>
                </span>
                <hr className='vertical-line mx-4' />
                <span>
                  Sub Division Code: <span className='font-medium'>{subDivisionCode}</span>
                </span>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div
                className='feeder-management-list list-view-box mt-2'
                style={{ paddingTop: 5 }}
              >
                <div className='search-heading'>
                  <div className='d-flex direction-row align-items-center'>
                    <div className='col-4 col-md-8 col-lg-6 col-xl-4'>
                      <div className='search-space position-relative ms-2'>
                        <input
                          type='text'
                          value={searchString}
                          onChange={(e) => {
                            setSearchString(e.target.value.trimLeft())
                          }}
                          placeholder='Search by MDM code'
                          className='form-control search-field'
                        />
                      </div>
                    </div>
                    {/* {isUserAE && (
                      <div className={styles.filterContainer}>
                        <div className={styles.filterHeading}>Filter By :</div>
                        <div
                          onClick={() => { handleFeederFilter('tagged') }}
                          className={`${styles.filterPill} mx-2 ${filterLoader === 'tagged' ? styles.filterLoading : ''}
                        ${selectedFilter === 'tagged' && !filterLoader ? styles.selectedFilter : ''}`}
                        >
                          Tagged
                          {selectedFilter === 'tagged' && !filterLoader &&
                            <img className={styles.closeIcon} src={closeIcon} alt='closeIcon' />}
                        </div>
                        <div
                          onClick={() => handleFeederFilter('untagged')}
                          className={`${styles.filterPill} ${filterLoader === 'untagged' ? styles.filterLoading : ''}
                        ${selectedFilter === 'untagged' && !filterLoader ? styles.selectedFilter : ''}`}
                        >
                          Untagged
                          {selectedFilter === 'untagged' && !filterLoader &&
                            <img className={styles.closeIcon} src={closeIcon} alt='closeIcon' />}
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
                {/* <div className="substation-list"> */}

                <div className='table-responsive'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th
                          className='pl-15 text-center'
                          scope='col'
                          style={{ borderBottom: '1px solid #dddddd' }}
                        >
                          #
                        </th>
                        <th
                          scope='col'
                          className='text-center'
                          style={{ borderBottom: '1px solid #dddddd' }}
                        >
                          Feeder Number
                        </th>
                        <th
                          scope='col'
                          className='text-center'
                          style={{ borderBottom: '1px solid #dddddd' }}
                        >
                          Feeder Name
                        </th>
                        <th
                          scope='col'
                          className='text-center'
                          style={{ borderBottom: '1px solid #dddddd' }}
                        >
                          Feeder MDM Code
                        </th>
                        <th
                          scope='col'
                          className='text-center'
                          style={{ borderBottom: '1px solid #dddddd' }}
                        >
                          Power station
                        </th>
                        <th
                          scope='col'
                          className='text-center'
                          style={{ borderBottom: '1px solid #dddddd' }}
                        >
                          Added Date
                        </th>
                        {/* {isUserAE &&
                          <th
                            scope='col'
                            className='text-center'
                            style={{ borderBottom: '1px solid #dddddd' }}
                          >
                            Action
                          </th>} */}

                      </tr>
                    </thead>
                    <tbody className='text-center'>
                      {feederLoader
                        ? (
                          <NoDataFound message='Loading...' />
                          )
                        : feeders.length === 0
                          ? (
                            <NoDataFound message='No Feeders found!' />
                            )
                          : (
                              feeders.map((feeder, index) => {
                                const isTaggedToAE = feeder?.taggedAEs?.some(aeDetails => aeDetails.location?.sectionCode === sectionCode)
                                return (
                                  <tr key={feeder.id} style={{ verticalAlign: 'middle' }}>
                                    <td
                                      className='ps-3 text-center'
                                      scope='row'

                                    >

                                      {(feederPageData.currentPage - 1) * 10 + (index + 1)}
                                    </td>
                                    <td className='text-center'>{feeder.number ?? '----'}</td>
                                    <td className='text-center'>{feeder.name ?? '----'}</td>
                                    <td className='text-center'>{feeder.mdmCode ?? '----'}</td>
                                    <td className='text-center'>{feeder?.powerStation?.name ?? '----'}</td>
                                    <td className='text-center'>{DateToDDMMYYYY(feeder.createdAt)}</td>
                                    {/* {isUserAE &&
                                      <td className='text-center pe-3'>
                                        {isTaggedToAE
                                          ? (
                                            <button
                                              type='button'
                                              className='btn btn-primary custom-active w-100'
                                              onClick={handleFeederUnTagging}
                                            >UNTAG
                                            </button>
                                            )
                                          : (
                                            <button
                                              type='button'
                                              disabled={tagButtonLoader === feeder.mdmCode}
                                              className='btn btn-primary custom-active w-100'
                                              onClick={() => handleFeederTagging(feeder?.powerStation?.stationCode, feeder.mdmCode)}
                                            >
                                              TAG {'\t'}
                                              {tagButtonLoader === feeder.mdmCode && (
                                                <span
                                                  className='spinner-grow spinner-grow-sm'
                                                  role='status'
                                                  aria-hidden='true'
                                                />
                                              )}
                                            </button>

                                            )}
                                      </td>} */}
                                  </tr>
                                )
                              })
                            )}

                    </tbody>
                  </table>
                </div>
                <div className='pe-2'>
                  <Paginator
                    currentPage={feederPageData.currentPage}
                    lastPage={feederPageData.lastPage}
                    onPressNext={() => {
                      fetchFeeders(feederPageData.currentPage + 1, selectedFilter)
                    }}
                    onPressPrevious={() => {
                      fetchFeeders(feederPageData.currentPage - 1, selectedFilter)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
