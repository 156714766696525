import React from 'react'
import { Doughnut } from 'react-chartjs-2'

const BillingDetailsChart = ({ data = [0, 0, 0, 0, 0, 0] }) => {
  const billingDetailsChartData = {
    labels: [
      'Active Installations',
      'Doorlock',
      'Unbilled',
      'Vacant',
      'MNR',
      'Zero Consumption'
    ],
    datasets: [
      {
        label: 'Rainfall',
        backgroundColor: [
          '#3B1FED',
          '#D81B60',
          '#9C27B0',
          '#FF9800',
          '#C0CA33',
          '#3EAEFF'
        ],
        hoverBackgroundColor: [
          '#501800',
          '#D81B60',
          '#175000',
          '#003350',
          '#35014F',
          '#501800'
        ],
        data,
        cutout: 50,
        center: 40
      }
    ]

  }

  return (
    <div style={{ margin: 'auto', width: '70%' }}>
      <Doughnut
        data={billingDetailsChartData}
        options={{
          plugins: {
            legend: {
              position: 'right',
              display: true,
              align: 'middle',
              labels: {
                color: '#686A6C'
              }
            },
            title: {
              display: false,
              text: 'Chart.js Doughnut Chart'
            }
          },
          responsive: true,
          maintainAspectRatio: true
        }}
      />
    </div>
  )
}

export default BillingDetailsChart
