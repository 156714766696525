import React from 'react'
import { Pie } from 'react-chartjs-2'

const DTCEnergyAuditChart = ({ totalTCs = 0, audited = 0, unaudited = 0 }) => {
  const data = {
    labels: ['Total', 'Audited', 'Unaudited'],
    datasets: [
      {
        label: '# of Votes',
        data: [totalTCs, audited, unaudited],
        backgroundColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(255, 99, 132, 1)'
          // 'rgba(75, 192, 192, 1)'
          // 'rgba(153, 102, 255, 1)',
          // 'rgba(255, 159, 64, 1)'
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(255, 99, 132, 1)'
          // 'rgba(75, 192, 192, 1)'
          // 'rgba(153, 102, 255, 1)',
          // 'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1
      }
    ]
  }

  const options = {
    // responsive: true,
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: true, // Show the legend
        position: 'right' // Position: 'top', 'bottom', 'left', 'right'
      },
      tooltip: {
        enabled: true // Show tooltips
      }
    }
  }
  //   const energyAuditChartData = {
  //     labels: ['DTC Energy Audit'],
  //     datasets: [
  //       {
  //         label: 'Total TCs',
  //         backgroundColor: 'rgba(59, 31, 237, 1)',
  //         borderColor: 'rgba(156, 39, 176, 1)',
  //         borderWidth: 0,
  //         data: [totalTCs]
  //       },
  //       {
  //         label: 'TC Audited',
  //         backgroundColor: 'rgba(156, 39, 176, 1)',
  //         borderColor: 'rgba(59, 31, 237, 1)',
  //         borderWidth: 0,
  //         data: [audited]
  //       },
  //       {
  //         label: 'TC Unaudited',
  //         backgroundColor: 'rgba(216, 27, 96, 1)',
  //         borderColor: 'rgba(59, 31, 237, 1)',
  //         borderWidth: 0,
  //         data: [unaudited]
  //       }
  //     ]
  //   }

  return (
    <div style={{
      width: '70%',
      margin: 'auto'
    }}
    >
      <Pie data={data} options={options} />
    </div>
  )

//   return (
//     <div style={{ paddingTop: 35, paddingBottom: 0 }}>
//       <Bar
//         data={energyAuditChartData}
//         options={{
//           elements: {
//             bar: {
//               borderWidth: 2,
//               borderColor: '#D2DDEC'
//             },
//             point: {
//               hoverRadius: 0
//             }
//           },
//           scales: {
//             y: {
//               display: false
//             },
//             x: {
//               display: false,
//               ticks: {
//                 beginAtZero: true
//               }
//             }
//           },
//           plugins: {
//             legend: {
//               position: 'right',
//               display: true,
//               align: 'center',
//               labels: {
//                 padding: 10,
//                 boxWidth: 25
//               }
//             },
//             title: {
//               display: false,
//               text: 'Chart.js Doughnut Chart'
//             },
//             tooltips: {
//               enabled: true
//             }
//           },
//           responsive: true
//           // maintainAspectRatio: true
//         }}
//       />
//     </div>
//   )
}

export default DTCEnergyAuditChart
